import Api from "../util/api";

async function load(adventure) {
  const payload = {
    regionCode:adventure.regionCode,
    participantCount:adventure.participantCount
  }
  const result = await Api("delegate", "loadCatalogue", payload);
  if(result.success) {
    // enrich the catalogue to make dealing with it easier
    result.activities.forEach(a => {
      // add two properties that contain selected optionals
      a.__selectedOptions = [];
      a.__selectedSubactivities = [];
      // add the subactivities and options as optionals
      a.__optionals = []
      a.regionVariant.options.forEach(option => {
        a.__optionals.push({kind:"option", obj:option})
      });
      (a.subactivities || [])
        .map(item => result.activities.find(a => a._id === item.activity_id) || null)
        .filter(sa => sa !== null)
        .forEach(sa => {
          if(sa.isOptionalSubactivity) {
            a.__optionals.push({kind:"subactivity", obj:sa})
          }
          else {
            // regular subactivities are always added
            a.__selectedSubactivities.push(sa);
          }
        })
    })
  }
  
  return result;
}

export default {
  load
}