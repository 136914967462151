import api from "./api";
import app from "./app";
import environment from "./environment";
import payrexx from "./payrexx";
import storage from "./storage";

export default {
  api:api[environment],
  app:app[environment],
  environment,
  payrexx:payrexx[environment],
  storage
}