import Api from "../util/api";

async function subscribeToNewsletter(email) {
  const result = await Api("delegate", "subscribeToNewsletter", {email});
  return result;
}

async function loadAdventure(adventureId) {
  const result = await Api("delegate", "loadAdventure", {adventureId});
  return result;
}

async function submitAdventure(adventureId, charity, activities) {
  const result = await Api("delegate", "submitAdventure", {adventureId, charity, activities});
  return result;
}

async function addFundsInitialize(adventureId, amount, buddy, sourceUrl) {
  const urlSuccess = sourceUrl + "?payment=success";
  const urlFailed = sourceUrl + "?payment=failed";
  const urlCancel = sourceUrl + "?payment=cancel";
  const currency = "CHF"; // TODO this is hardcoded
  const result = await Api("delegate", "addFundsInitialize", {adventureId, amount, currency, buddy, urlSuccess, urlFailed, urlCancel});
  return result;
}

export default {
  subscribeToNewsletter,
  loadAdventure,
  submitAdventure,
  addFundsInitialize
}