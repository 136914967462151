import React, {useContext, useState, useRef, useEffect} from "react";
import {AppContext} from "../../context/App.Context";
import CartLogic from "../../context/Cart.Logic";
import {Button} from "../controls";
import {MdWarning, MdDone} from "react-icons/md";
import Images from "../images";
import "./Cart.scss";

/**
 * Displays the cart
 */
export default function Cart() {
  // context
  const App = useContext(AppContext);
  // state
  const [minimized, setMinimized] = useState(false);
  // ref to cart and a ref to store its (last) height
  const cartRef = useRef(); // we use this so we can resize the catalogue bottom to reflect the height of the cart
  const cartHeighRef = useRef(0);
  const setCartHeight = App.setCartHeight;

  // check size of cart on each render and broadcast when changed (Catalogue needs to know this)
  useEffect(() => {
    if(cartRef.current) {
      const height = cartRef.current.offsetHeight;
      if(height !== cartHeighRef.current) {
        setCartHeight(height);
      }
    }
  });

  // render
  return (
    <div id="v-cart" ref={cartRef}>
      <div id="v-cart-inner">
        {minimized ? <SummaryMinimized /> : <Summary /> }
        <Continue />
        <div id="v-cart-toggle" className={minimized ? "rotated": ""}>
          <div id="v-cart-toggle-inner" onClick={() => setMinimized(!minimized)}>
            <img src={Images.icons.white.chevron} alt="toggle" />
          </div>
        </div>
      </div>
    </div>
  )
}

function SummaryMinimized() {
  // context
  const App = useContext(AppContext);
  // calculate remaind
  const remainder = App.adventure.value - App.cart.total;
  let remainderText = remainder === 0 ? "" : remainder > 0 ? ` (noch ${remainder}.- verfügbar)` : ` (du liegst ${-1 * remainder}.- über dem Budget)`
  // render
  const items = App.cart.items.map(activity => `${activity.title} (${CartLogic.getActivityPricing(activity).total}.-)`);
  return <div>{items.join(', ')} = {App.cart.total}.- {remainderText}</div>
}

function Summary() {
  // context
  const App = useContext(AppContext);
  
  // items
  const items = App.cart.items.map(activity => {
    const pricing = CartLogic.getActivityPricing(activity);
    return (
      <tr className="activity" key={activity._id}>
        <td className="image"><img src={activity.defaultImage.url} alt={activity.title} /></td>
        <td>
          {activity.title}
        </td>
        <td className="right">
          {Number(pricing.total).toFixed(2)}
        </td>
        <td className="nowrap">
          <Button onClick={() => App.removeFromCart(activity)} size="xsmall" kind="delete">entfernen</Button>
          <Button onClick={() => App.setActivityToCustomize(activity)} size="xsmall">bearbeiten</Button>
        </td>
      </tr>
    )
  })

  // budget & remainder
  const remainder = App.adventure.value - App.cart.total;
  const remainderIcon = remainder < 0 ? <MdWarning /> : <MdDone />;
  const totals = <>
    <tr className="total">
      <td></td>
      <td>Verwendet</td>
      <td className="right subtotal">{Number(App.cart.total).toFixed(2)}</td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td>Budget</td>
      <td className="right">{App.adventure.value.toFixed(2)}</td>
      <td><Button onClick={() => App.setMode("addfunds")} size="xsmall">Budget vergrössern</Button></td>
    </tr>
    <tr className={remainder < 0 ? "error" : "ok"}>
      <td></td>
      <td>Rest</td>
      <td className="right">{remainder.toFixed(2)}</td>
      <td>{remainderIcon}</td>
    </tr>
  </>

  
  // render
  return (
    <div id="v-cart-summary">
      <table><tbody>
        {items}
        {totals}
      </tbody></table>
    </div>
  )
}

function Continue() {
  // context
  const App = useContext(AppContext);

  // render
  const remainder = App.adventure.value - App.cart.total;
  let info = null;
  if(remainder < 0) {
    info = (
      <div id="v-cart-continue-overbudget">
        Leider liegst du mit dieser Konfiguration über dem Budget - du darfst gerne das <span className="addfunds" onClick={() => App.setMode("addfunds")}>Budget vergrössern</span>
      </div>
    )
  }
  else {
    info = (
      <div id="v-cart-continue-charity">
        Budget nicht ausgeschöpft? Wähle im nächsten Schritt eine wohltätige Organisation, welcher wir im Namen des Beschenkten den Restbetrag übergeben.
      </div>
    )
  }
  return (
    <div id="v-cart-continue">
      {info}
      <div id="v-cart-continue-actions">
        <Button disabled={remainder < 0} onClick={() => App.setMode("finalize")}>weiter</Button>
      </div>
    </div>
  )
}


