const config = {
  development: {
    instance:"appentura-test"
  },
  staging: {
    instance:"appentura-test"
  },
  production: {
    instance:"appentura"
  }
};

export default config