import React, {useContext} from "react";
import {AppContext} from "../../context/App.Context";
import {Logo} from "../controls";
import {Footer} from "../subviews";
import Images from "../images";
import "./Done.scss";

export default function Done() {
  // context
  const App = useContext(AppContext);

  // render
  return (
    <div id="v-done">
      <div id="v-done-inner">
        <div className="heading">
          <Logo size="medium" color="black" />
        </div>
        <div className="happy">
          <img src={Images.icons.black.happy} alt="happy face" />
        </div>
        <h1>Dein Job ist erledigt</h1>
        <p>
          Auf {App.adventure.adventurerName} wartet ein tolles Erlebnis!
        </p>
        <p>
          Möchtest du selber mal jemanden überraschen? Dann schau rein auf <a href="https://shop.appentura.ch" target="_blank" rel="noopener noreferrer">shop.appentura.ch</a>.
        </p>
      </div>
      <Footer />
    </div>
  );
}
