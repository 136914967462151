import React from "react";
import "./Circle.scss";

export default function Circle({children, onClick, className}) {
  const click = () => {
    if(onClick) {
      onClick();
    }
  }
  return (
    <div className={`c-circle clickable ${className}`} onClick={click}>
      <div className="c-circle-inner">
        {children}
      </div>
    </div>
  )
}

