const config = {
  development: {
    root:`http://${window.location.hostname}:8090/buddy`
  },
  staging: {
    root:"https://api-staging.appentura.io/buddy"
  },
  production: {
    root:"https://api.appentura.io/buddy"
  }
};

export default config