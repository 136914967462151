import React, {useContext} from "react";
import {AppContext} from "../../context/App.Context";
import {Button} from "../controls";
import Images from "../images";
import "./PaymentResult.scss";

function PaymentResult({result}) {
  // context
  const App = useContext(AppContext);

  // not yet ready? render nothing
  if(!App.isLoaded) {
    return null;
  }

  // user wants to close the dialog
  const onClickClose = () => {
    window.location.href = window.location.href.split('?')[0];
  };

  // content depending on result
  let image = null;
  let title = null;
  let text = null;
  let buttonTitle = null;
  switch(result) {
    case "cancel":
      title = "Zahlung abgebrochen";
      text = <>
        <p>Die Zahlung wurde abgebrochen</p>
      </>
      buttonTitle = "schade";
      image = Images.icons.black.sad;
      break;
    case "failed":
      title = "Zahlung konnte nicht abgeschlossen werden";
      text = <>
        <p>Bei der Zahlungsverarbeitung ist ein Fehler passiert.</p>
        <p>Vielleich versuchen wir es einfach noch einmal?</p>
      </>
      buttonTitle = "ok, kommt vor";
      image = Images.icons.black.sad;
      break;
    case "success":
    default:
      title = "Das hat super funktioniert";
      text = <>
        <p>
          Das Erlebnis von {App.adventure.adventurerName} kann jetzt noch viel besser werden!
        </p>
        <p>
          Tausend Dank :)
        </p>
      </>
      image = Images.icons.black.happy;
      buttonTitle = "prima, weiter geht's"
      break;
  }

  // render
  return <div id="v-payment-result">
    <div id="v-payment-result-inner">
      <div className="smiley">
        <img src={image} alt="face" />
      </div>
      <h1>{title}</h1>
      <div className="text">{text}</div>
      <Button onClick={onClickClose}>{buttonTitle}</Button>
    </div>
  </div>
}

export default PaymentResult;