import React from "react"
import "./Button.scss";

function Button({onClick, label, data, disabled, children, kind, size}) {
  const _onClick = (e) => {
    e.preventDefault()
    if(onClick && !disabled) {
      onClick(data)
    }
  }
  // note: using a div because button's font-weight cannot be properly styled in some versions of chrome
  return (
    <div onClick={_onClick} className={`c-button ${disabled ? "disabled" : kind || "primary"} ${size || "normal"}`}>
      <div className="c-button-inner">
        {children || label}
      </div>
    </div>
  )
}

export default Button