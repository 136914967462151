import React, {useEffect, useState, useContext} from "react";
import EmailValidator from "email-validator";
import AppLogic from "../../context/App.Logic";
import {AppContext} from "../../context/App.Context";
import {Button, Loader, NumberInput, TextInput} from "../controls";
import {AdventureInfo, Footer} from "../subviews";

import "./AddFunds.scss";

export default function AddFunds() {
  // context
  const App = useContext(AppContext);
  
  // state
  const [validation, setValidation] = useState({valid:false, email:false, amount:false});
  const [mode, setMode] = useState("start"); // "start", "done", TODO "error" ...
  const [amount, setAmount] = useState(calculateMissingAmount(App.cart.total, App.adventure.value));
  const [email, setEmail] = useState(App.adventure.buddyEmail || "");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // validate when amount or email changes
  useEffect(() => {
    validate();
  }, [amount, email])


  // start payment process
  const startPayment = () => {
    const buddy = {
      email: email.trim(), firstName: firstName.trim(), lastName: lastName.trim()
    }
    App.setModal(<Payrexx amount={amount} buddy={buddy} onCancel={onCancel} onSuccess={onSuccess} onError={onError} />, {hideClose:true});
  }

  // payment process canceled
  const onCancel = async() => {
    await App.loadAdventure(App.adventure.id, "addfunds");
    App.setModal(null);
  }

  // payment was successful
  const onSuccess = async() => {
    await App.loadAdventure(App.adventure.id, "addfunds");
    App.setModal(null);
    setMode("done");
  }

  // payment resulted in error
  const onError = async(transaction) => {
    App.setModal(null);
    setMode("error");
  }

  // validates the user input
  const validate = () => {
    const amountValid = amount > 1;
    const emailValid = EmailValidator.validate(email);
    setValidation({
      valid:amountValid && emailValid,
      amount: amountValid,
      email: emailValid,
    });
  }

  // render
  let content = null;
  const buttonAbort = <Button onClick={() => App.setMode("ready")} kind="delete">abbrechen</Button>
  if(mode === "start") {
    content = <>
      <p>Reicht das vorgegebene Budget nicht aus, um die perfekte Überraschung zusammenzustellen?</p>
      <p>Vergrössere ganz einfach den zur Verfügung stehenden Betrag :)</p>
      <br />
      <h2>Betrag wählen</h2>
      <p>Wähle den Betrag, um welchen du das Budget erweitern möchtest ...</p>
      <div className="amount-and-submit">
        <div className="fields">
          <TextInput label="deine E-Mail" initialValue={email} onChange={v => setEmail(v)} className="email" />
          <div className="name">
            <TextInput label="Vorname" initialValue={firstName} onChange={v => setFirstName(v)} className="firstName" />
            <TextInput label="Name" initialValue={lastName} onChange={v => setLastName(v)} className="lastName" />
          </div>
          <NumberInput label="Betrag um den erhöhen möchtest (in CHF)" initialValue={amount} onChange={v => setAmount(v) } className="amount"/>
        </div>

        <div className="validation">
          {validation.email ? null : <div className="validation-item">Gib bitte eine gültige E-Mail Adresse ein</div>}
          {validation.amount ? null : <div className="validation-item">Gib bitte einen Betrag grösser als 0 ein</div>}
        </div>
        
        <div className="buttons">
          <Button disabled={validation.valid === false} onClick={startPayment}>bezahlen</Button>
          {buttonAbort}
        </div>
      </div>
    </>
  }
  if(mode === "done") {
    content = <>
      <h2>Alles hat geklappt ... </h2>
      <p>Super! Vielen Dank ... der perfekten Überraschung steht jetzt nicht mehr im Weg :)</p>
      <Button onClick={() => App.setMode("ready")}>Weiter machen :)</Button>
    </>
  }
  if(mode === "error") {
    content = <>
      <h2>Da ist etwas schief gelaufen :(</h2>
      <p>Am besten versuchen wir es noch einmail ...</p>
      <div className="buttons">
        <Button onClick={() => setMode("start")}>Nochmals versuchen</Button>
        {buttonAbort}
      </div>
    </>
  }
  
  // note: link parameters: https://developers.payrexx.com/docs/modal-window ... e-mail is not displayed ...
  // note: need to url-encode the data-href?
  return <>
    <AdventureInfo />
    <div id="v-addfunds">
      <div id="v-addfunds-inner">
        <h1>Budget vergrössern</h1>
        {content}
      </div>
      <Footer />
    </div>
    
  </>
}

function Payrexx({amount, buddy, onCancel, onSuccess, onError}) {
  // context
  const App = useContext(AppContext);
  // state
  const [mode, setMode] = useState("initializing"); // "initializing", "redirecting", "error"

  // mount initialize the payment
  useEffect(() => {
    initializePayment();
  }, []);

  // get gateway link
  const initializePayment = async () => {
    // get the gateway
    const sourceUrl = window.location.href.split('?')[0];
    const gatewayResult = await AppLogic.addFundsInitialize(App.adventure.id, amount, buddy, sourceUrl);
    if(gatewayResult.success) {
      let gwl = gatewayResult.data.gateway.link;
      window.location.href = gwl;
    }
    else {
      setMode("error");
    }
  }

  const onClickRetry = () => {
    window.location.reload();
  }

  // render
  let content = null;
  if(mode === "initializing") {
    content = <div>
      <p>bereite Zahlung vor ...</p>
      <Loader />
    </div>
  }
  if(mode === "error") {
    content = <div>
      <div>da ist etwas schief gelaufen</div>
      <div onClick={onClickRetry}>noch mal versuchen</div>
    </div>
  }

  return content

}

function calculateMissingAmount(total, value) {
  // calculate missing amount
  let missingAmount =  total - value;
  // if no amount is missing, we can reset it to 0
  if(missingAmount < 0) {
    missingAmount = 0;
  }
  return missingAmount;
}