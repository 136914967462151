import React, {useState} from "react";
import "./Input.scss";

export default function TextInput({onChange, initialValue, disabled, label, className}) {
  // state
  const [value, setValue] = useState(initialValue || "");

  // change
  const change = (e) => {
    setValue(e.target.value);
    if(onChange) {
      onChange(e.target.value);
    }
  }

  // render
  return (
    <div className={`c-input c-textinput ${className}`}>
      <label>
        <div className="c-input-label">{label}</div>
        <input type="text" onChange={change} value={value} disabled={disabled} />
      </label>
    </div>
  );
}
