import React, {useState, useContext} from "react";
import {validate as validateEmail} from "email-validator";
import {AppContext} from "../../context/App.Context";
import Images from "../images";
import {ExternalLink, Button} from "../controls"

import "./Footer.scss"

export default function Footer() {
  // context
  const App = useContext(AppContext);

  // state management
  const [newsletterState, setNewsletterState] = useState({email:"", valid:"", subscribed:false});
  const [minimized, setMinimized] = useState(true);

  // handles changes to the newsletter e-mail textinput
  const onNewsletterEmailChange = (e) => {
    let email = e.target.value;
    let valid = validateEmail(email.trim());
    setNewsletterState({...newsletterState, email, valid});
  }

  // handles clicks on the newsletter subscribe button
  const onClickNewsletterSubscribe = async(e) => {
    e.preventDefault()
    // TODO visualize that it is busy
    await App.subscribeToNewsletter(newsletterState.email);
    setNewsletterState({...newsletterState, subscribed: true});
  }

  let linkFAQ = <ExternalLink to="https://www.appentura.ch/faq/">FAQ</ExternalLink>
  let linkExplain = <ExternalLink to="https://www.appentura.ch/so-funktioniert-appentura/">So funktioniert's</ExternalLink>
  let linkAbout = <ExternalLink to="https://www.appentura.ch/about/">Über uns</ExternalLink>
  let linkJobs = <ExternalLink to="https://www.appentura.ch/jobs/">Jobs</ExternalLink>
  let linkInstagram = <ExternalLink to="https://www.instagram.com/appentura/"><div className="icon"><img src={Images.social.instagram} alt="instagram" /></div></ExternalLink>
  let linkFacebook = <ExternalLink to="https://www.facebook.com/appentura"><div className="icon"><img src={Images.social.facebook} alt="facebook" /></div></ExternalLink>
  let linkTwitter = <ExternalLink to="https://twitter.com/appentura"><div className="icon"><img src={Images.social.twitter} alt="twitter" /></div></ExternalLink>
  let linkLinkedIn = <ExternalLink to="https://www.linkedin.com/company/appentura/?originalSubdomain=ch"><div className="icon"><img src={Images.social.linkedin} alt="linkedin" /></div></ExternalLink>
  let linkEmail = <a href="mailto:hello@appentura.ch">hello@appentura.ch</a>
  let linkAddress = <ExternalLink to="https://goo.gl/maps/Amid2APHyKSDupfX9">Appentura GmbH<br/>Bernstrasse 1<br />3066 Stettlen / Bern</ExternalLink>
  let linkPhone = <a href="tel:+41315112132">+41 31 511 21 32</a>
  let linkConditions = <ExternalLink to="https://www.appentura.ch/agb/">AGB</ExternalLink>
  let linkImprint = <ExternalLink to="https://www.appentura.ch/impressum">Impressum</ExternalLink>
  let linkDataProtection = <ExternalLink to="https://www.appentura.ch/datenschutz">Datenschutz</ExternalLink>

  // newsletter part
  let newsletter = null;
  if(!newsletterState.subscribed) {
    newsletter = <>
      <p>Abonniere unseren Newsletter und profitiere von exklusiven Angeboten:</p>
      <div className="form">
        <input 
          type="text"
          onChange={onNewsletterEmailChange}
          value={newsletterState.email}
          placeholder="deine E-Mail Adresse"
        />
        <button
          onClick={onClickNewsletterSubscribe}
          disabled={!newsletterState.valid}
        >
          ab die Post!
        </button>
      </div>
    </>
  }
  else {
    newsletter = <p>Vielen Dank für dein Interesse!</p>
  }

  // render
  if(minimized) {
    return (
      <footer>
        <div id="v-footer-minimized">
          <div>
            Appentura AG, {linkEmail}, {linkPhone}
          </div>
          <div className="console">
            <Button size="xsmall" kind="outline-inverted" onClick={() => setMinimized(false)}>mehr</Button>
          </div>
        </div>
      </footer>
    )
  }
  else {
    return (
      <footer>
        <div id="v-footer">
          <div id="v-footer-inner">
            <div className="row">
              <div className="primary-links">
                {linkFAQ}{linkExplain}{linkAbout}{linkJobs}
              </div>
              <div className="social">
                <div className="follow">
                  <span>Follow</span>
                  {linkInstagram}{linkFacebook}{linkTwitter}
                </div>
                <div className="connect">
                  <span>Connect</span>
                  {linkLinkedIn}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="contact">
                <div className="email">{linkEmail}</div>
                <div className="phone">{linkPhone}</div>
                <div className="address">{linkAddress}</div>
              </div>
              
              <div className="newsletter">
                {newsletter}
              </div>
              
            </div>
            <div className="divider" />
            <div className="row">
              <div className="seals"></div>
              <div className="secondary-links">
                {linkConditions}{linkImprint}{linkDataProtection}
              </div>
            </div>
            <div className="console">
              <Button size="xsmall" kind="outline-inverted" onClick={() => setMinimized(true)}>weniger</Button>
            </div>
          </div>
        </div>
      </footer>
    ) 
  }
}
