import React from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom"
import Config from "./config";

// styling 
import "./App.scss";

// components
import {Nil} from "./components/views";
import Main from "./components/Main";

// context provider
import {AppProvider} from "./context/App.Context"

// output some info
console.log(`buddy.appentura.ch - ${Config.environment}`);

// the component
export default function App() {
  return (    
    <AppProvider>
      <Router>
        <Switch>
          <Route path="/:id/:mode" component={Main} />
          <Route path="/:id" component={Main} />
          <Route component={Nil} />
        </Switch>
      </Router>
    </AppProvider>
  )
}
