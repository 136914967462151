import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Button, AsyncImage} from "../controls";

import "./Activity.scss";

function Activity({activity, onSelect}) {
  // state
  const [imageUrl, setImageUrl] = useState();
  // mount
  useEffect(() => {
    
    setImageUrl(_.get(activity, "defaultImage.url_thumbnail") || _.get(activity, "defaultImage.url") || null); // TODO add a fallback image
  }, [activity]);
  
  // render
  return (
    <div className="c-activity" onClick={() => onSelect(activity)}>
      <div className="c-activity-inner">
        <div className="image"><AsyncImage src={imageUrl} alt={activity.title} /></div>
        <div className="info">
          <div className="top">
            <div className="title">{activity.title}</div>
            <div className="shortDescription">{activity.shortDescription}</div>
          </div>
          <div className="bottom">
            <div className="price">CHF {Number(activity.regionVariant.priceConfiguration.price).toFixed(2)}</div>
            <Button onClick={() => onSelect(activity)}>wählen</Button>
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Activity