import React, {useContext, useEffect} from "react";
import {AppContext} from "../../context/App.Context";
import BuddyLogic from "../../context/Buddy.Logic";
import {Button, Logo} from "../controls";
import {Footer} from "../subviews";
import {FaRegSmileBeam} from "react-icons/fa";
import "./Start.scss";

function Start() {
  // context
  const App = useContext(AppContext);

  // mount
  useEffect(() => {
    if(App.adventure) {
      BuddyLogic.setIsFirstVisit(App.adventure.id, false);
    } 
  }, [App.adventure]);

  if(!App.adventure) {
    return null;
  }

  // render
  const a = App.adventure;
  let comment = null;
  if((a.buddySelectionComment || "").trim().length > 0) {
    comment = (
      <section>
        <h1>Nachricht von {a.adventurerName}</h1>
        <p>{a.adventurerName} hat noch eine Nachricht an dich hinterlassen:</p>
        <div className="comment">
          <div className="avatar"><FaRegSmileBeam /></div>
          <div className="speech-bubble">
            {(a.buddySelectionComment || "").split('\n').map((line, index) => <p key={index}>{line}</p>)}
          </div>
        </div>
      </section>
    )
  }
  return (
    <div id="v-start">
      <div id="v-start-inner">
        <header>
          <Logo size="medium" color="black" />
        </header>
        
        <section>
          <h1>Hallo {a.buddyName}</h1>
          <p>{a.adventurerName} hat dich dazu auserwählt, ein Appentura Überraschungs-Erlebnis zusammenzustellen.</p>
          <p>Das Erlebnis findet in der {a.regionTitle} statt und es {a.participantCount === 1 ? "wird 1 Person" : `werden ${a.participantCount} Personen`} teilnehmen. </p>
          <p>Es steht dir ein Budget von CHF {a.value.toFixed(2)} zur Verfügung um aus einer vielfältigen Auswahl an Aktivitäten ein einzigartiges Erlebnis zusammenzustellen.</p>
        </section>

        {comment}

        <section>
          <h1>Was ist Appentura?</h1>
          <p>Wir sind ein Berner Startup und sorgen mit Erlebnisgeschenken der neuen Dimension für unvergessliche Momente.</p>
          <p>Du bestimmst den Inhalt und wir führen {a.adventurerName} auf dem Smartphone Schritt für Schritt durch die Überraschung. Was der Inhalt ist, erfährt {a.adventurerName} erst vor Ort!</p>
        </section>

        <section>
          <h1>Wichtig!</h1>
          <p>Damit dies auch wirklich eine Überraschung bleibt, solltest du {a.adventurerName} nicht verraten, was du ausgesucht hast.</p>
        </section>

        <section>
          <h1>Übrigens ...</h1>
          <p>Du kannst diese Seite jederzeit wieder aufrufen, indem du den Hilfe-Knopf oben rechts drückst.</p>
        </section>
        <div className="console">
          <Button onClick={() => App.setModal(null)} size="wide">ok!</Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Start