import React from "react"
import {ExternalLink} from "../controls"
import {Logo} from "../controls";
import {Footer} from "../subviews";

import "./Nil.scss";

export default function Nil() {
  // TODO implement
  return (
    <div id="v-nil">
      <div id="v-nil-inner">
        <div>
          <Logo size="large" color="black" />
          <h1>Hallo :)</h1>
          <div className="text">
            <p>Lust ein unvergessliches Erlebnis zu verschenken?</p>
            <p>Besuche jetzt unseren <ExternalLink to="https://shop.appentura.ch">Online Shop</ExternalLink> und mache noch heute jemanden glücklich!</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
