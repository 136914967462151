import facebook from "./logo_facebook_white.svg";
import instagram from "./logo_instagram_white.svg";
import linkedin from "./logo_linkedin_white.svg";
import twitter from "./logo_twitter_white.svg";

import icon_chevron_white from "./icon_chevron_white.svg";
import icon_sad_black from "./icon_sad_black.svg";
import icon_happy_black from "./icon_happy_black.svg";

export default {
  social: {
    facebook, instagram, linkedin, twitter
  },
  icons: {
    white: {
      chevron: icon_chevron_white
    },
    black: {
      sad: icon_sad_black,
      happy: icon_happy_black
    }
  }
}