import React, {useState, useEffect} from "react"

// context
const ScrollContext = React.createContext()

// provider
const ScrollProvider = (props) => {
  const [timestamp, setTimestamp] = useState(Date.now())

  const scrollHandler = (e) => {
    setTimestamp(Date.now())
  }

  // handle scroll event
  useEffect(() => {
    // subscribe to scroll event
    window.addEventListener('scroll', scrollHandler, true)

    // unsubscribe scroll event
    return () => { 
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])


  let providerValue = {
    timestamp,
    getScrollY:() => {return window.scrollY}
  }

  return (
    <ScrollContext.Provider value={providerValue}>
      {props.children}
    </ScrollContext.Provider>
  )
}

export {ScrollContext, ScrollProvider}

