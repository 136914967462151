import superagent from "superagent"
import config from '../config'

async function Api(endpoint, action, payload) {
  return new Promise((resolve) => {
    let data = { action, payload }
    superagent.post(`${config.api.root}/${endpoint}`)
      .send(data)
      .end((err, res) => {
        if (err) {
          resolve({
            success:false, 
            error:err
          })
        } 
        else {
          resolve(res.body)
        }
      })
  })
}

export default Api