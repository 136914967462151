import _ from "lodash";
import React, {useState, useEffect, useContext} from "react";
import {AppContext} from "../../context/App.Context";
import CartLogic from "../../context/Cart.Logic";
import {Button} from "../controls";
import "./Customize.scss";

export default function Customize({activity, onClose}) {
  // get the context
  const App = useContext(AppContext);
  
  // state
  const [selectedSubactivities, setSelectedSubactivities] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [galleryItems, setGalleryItems] = useState([]);
  
  // mount
  useEffect(() => {
    setSelectedOptions(activity.__selectedOptions || []);
    setSelectedSubactivities(activity.__selectedSubactivities || []);
    // gallery items
    setGalleryItems(activity.images.sort((a, b) => a.default ? -1 : 1).map(image => image));
  }, [activity]);

  // add / remove optional
  const changeOptional = (optional, value) => {
    if(optional.kind === "option") {
      const option = optional.obj;
      // remove it
      const _selectedOptions = selectedOptions.filter(o => o._id !== option._id).map(o => o);
      // add it if necessary
      if(value > 0) {
        _selectedOptions.push({_id:option._id, units:Number(value), price:option.price, name:option.name, description:option.description})
      }
      activity.__selectedOptions = _selectedOptions;
      setSelectedOptions(_selectedOptions);
    }
    else if(optional.kind === "subactivity") {
      // remove it
      const subactivity = optional.obj;
      const _selectedSubactivities = selectedSubactivities.filter(o => o._id !== subactivity._id).map(o => o);
      // add it if necessary
      if(value) {
        _selectedSubactivities.push(subactivity);
      }
      activity.__selectedSubactivities = _selectedSubactivities;
      setSelectedSubactivities(_selectedSubactivities);
    }
  }

  // add activity
  const addActivity = () => {
    activity.__selectedOptions = selectedOptions;
    activity.__selectedSubactivities = selectedSubactivities;
    App.addToCart(activity);
    onClose();
  }
  // remove acitivity
  const removeActivity = () => {
    App.removeFromCart(activity);
    onClose();
  }


  // render
  return (
    <div id="v-customize">
      <Gallery items={galleryItems} />
      <h1>{activity.title}</h1>
      <div className="shortDescription">
        {activity.shortDescription.split('\n').map((line, index) => <p key={index}>{line}</p>)}
      </div>
      <div className="longDescription">
        {activity.longDescription.split('\n').map((line, index) => <p key={index}>{line}</p>)}
      </div>
      {(activity.__optionals.length > 0) && 
      <div className="options">
        <h2>Optionen</h2>
        {activity.__optionals.map(o => <Optional key={o.obj._id} optional={o} selectedOptions={selectedOptions} selectedSubactivities={selectedSubactivities} onChange={changeOptional} />)}
      </div>
      }
      <div>
        <h3>Preis</h3>
        <Prices activity={activity} options={selectedOptions} subactivities={selectedSubactivities} />
      </div>
      <Console activity={activity} onAdd={addActivity} onRemove={removeActivity} />
    </div>
  );
}

/**
 * Displays the console containing action buttons
 */
function Console({activity, onAdd, onRemove}) {
  // get the context
  const App = useContext(AppContext);

  // render
  let content = null;
  if(App.isInCart(activity)) {
    content = <>
      <Button onClick={onRemove} kind="delete">entfernen</Button>
      <Button onClick={onAdd}>übernehmen</Button>
    </>
  }
  else {
    content = <>
      <Button onClick={onRemove} kind="secondary">zurück</Button>
      <Button onClick={onAdd}>hinzufügen</Button>
    </>
  }
  return <div className="console">{content}</div>
}

/**
 * Displays the Pricing structure (base price, options, optional subactivities)
 */
function Prices({activity, options, subactivities}) {
  // get the pricing structure
  const pricing = CartLogic.getActivityPricing(activity);

  // create table rows
  const rows = []
  const hasOptionals = activity.__optionals.length > 0;
  // the (base) prcie
  rows.push(
    <tr key="base" className={hasOptionals ? "" : "total"}>
      <td>{hasOptionals ? "Grundpreis" : "Preis"} (für {pricing.participantCount} Person{pricing.participantCount > 1 ? "en" : ""})</td>
      <td>CHF {Number(pricing.base).toFixed(2)}</td>
    </tr>
  )
  // selected options & optional activities
  pricing.options.forEach((o, index) => {
    rows.push(
      <tr key={index}>
        <td>{o.units} &times; {o.title}</td>
        <td>CHF {Number(o.price).toFixed(2)}</td>
      </tr>
    )
  })

  // add the total (only if there are options available)
  if(hasOptionals) {
    rows.push(
      <tr key="total" className="total">
        <td>Summe</td>
        <td>CHF {Number(pricing.total).toFixed(2)}</td>
      </tr>
    )
  }

  // render
  return (
    <table className="prices"><tbody>
      {rows}
    </tbody></table>
  )
}

/**
 * Displays an optional (i.e. Option or Optional Subactivity)
 */
function Optional({optional, selectedOptions, selectedSubactivities, onChange}) {
  const [value, setValue] = useState(0);

  const change = (e) => {
    setValue(e.target.value);
    onChange(optional, e.target.value);
  }

  useEffect(() => {
    if(optional.kind === "option") {
      const selectedOption = selectedOptions.find(o => o._id === optional.obj._id);
      if(selectedOption) {
        setValue(selectedOption.units);
      }
    }
    else if(optional.kind === "subactivity") {
      const selectedSubactivity = selectedSubactivities.find(sa => sa._id === optional.obj._id);
      if(selectedSubactivity) {
        setValue(true);
      }
    }
  }, [optional, selectedOptions, selectedSubactivities])

  // render
  if(optional.kind === "option") {
    const option = optional.obj;
    const opts = [];
    for(let units = option.minUnits; units <= option.maxUnits; units+=1) {
      const price = Number(option.price) * units;
      opts.push(<option key={`${option._id}_${units}`} value={units}>{units} (+ CHF {Number(price).toFixed(2)})</option>)
    }
    return (
      <div className="option">
        <h3>{option.name}</h3>
        <p>{option.description}</p>
        <select value={value} onChange={change}>
          {opts}
        </select>
      </div>
    )
  }
  else {
    const subactivity = optional.obj; 
    const price = subactivity.regionVariant.priceConfiguration.price;
    return (
      <div className="option">
        <h3>{subactivity.title}</h3>
        <p>{subactivity.shortDescription}</p>
        <select value={value} onChange={change}>
          <option value={false}>nein (+ CHF 0.00)</option>
          <option value={true}>ja (+ CHF {Number(price).toFixed(2)})</option>
        </select>
      </div>
    )
  } 
}

function Gallery({items}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  if(!items || items.length === 0) {
    return <div></div>
  }
  else {
    return (
      <div className="gallery">
        <div className="current">
          <img src={items[currentIndex].url} alt={_.get(items[currentIndex], "seo.de") || "bild"} />
        </div>
        <div className="others">
          {items.map((item, index) => {
            return (
              <img key={index} src={items[index].url} alt={_.get(items[currentIndex], "seo.de") || "bild"} onClick={() => setCurrentIndex(index)} />
            )
          })}
        </div>
      </div>
    )

  }

}