import React from "react";
import "./Logo.scss";

import logo_black from "./images/Appentura_black.svg";
import logo_white from "./images/Appentura_black.svg";


export default function Logo({size, color}) {
  // render
  return (
    <div className={`c-logo ${size || "medium"}`}> 
      <img src={color === "white" ? logo_white : logo_black} alt="Appentura" />
    </div>
  );
}

