import Config from "../config";

function getActivityPricing(activity) {
  let total = 0;
  // base price
  const base = activity.regionVariant.priceConfiguration.price;
  const participantCount = activity.regionVariant.priceConfiguration.participantCount;
  total += base;
  // options & optional subactivities
  const options = [];
  activity.__selectedSubactivities.filter(sa => sa.isOptionalSubactivity).forEach(sa => {
    const price = sa.regionVariant.priceConfiguration.price;
    options.push({
      kind:"subactivity",
      title:sa.title,
      units:sa.regionVariant.priceConfiguration.participantCount,
      price:price
    });
    total += price;
  })
  activity.__selectedOptions.forEach(o => {
    const price = o.units * o.price;
    options.push({
      kind:"option",
      title:o.name,
      units:o.units,
      price
    });
    total += price;
  })
  
  return {
    participantCount, base, total, options
  }
}

function getTotal(itemsInCart) {
  let total = 0;
  itemsInCart.forEach(item => {
    let pricing = getActivityPricing(item)
    total += pricing.total;
  })
  return total;
}

function store(adventure, cart) {
  // TODO store the selected charity?
  const key = Config.storage.makeKey(adventure.id);
  localStorage.setItem(key, JSON.stringify(cart));
}

function restore(adventure) {
  // TODO restore the selected charity?
  const key = Config.storage.makeKey(adventure.id);
  const value = localStorage.getItem(key);
  if(value) {
    try {
      return JSON.parse(value);
    }
    catch(err) {
      console.error("could not restore cart from localStorage");
      return null
    }
  }
}

export default {
  getActivityPricing,
  getTotal,
  store,
  restore
}