import _ from "lodash";
import React, {useContext, useEffect, useRef, useState} from "react"
import QueryString from "query-string";
import {AppContext} from "../context/App.Context";
import Config from "../config";
import BuddyLogic from "../context/Buddy.Logic";
import {ScrollProvider} from "../context/Scroll.Context";
import {Catalogue, Done, Error, Finalize, Loading, Start, AddFunds, PaymentResult} from "./views";
import {Circle} from "./controls";

// styling
import "./Main.scss"

// the component
function Main(props) {
  // get the context
  const App = useContext(AppContext);
  // refs to context methods used in useEffect
  const loadAdventure = useRef(App.loadAdventure);
  const setModal = useRef(App.setModal);
  const setMode = useRef(App.setMode);

  // mount
  useEffect(() => {
    // get id
    const id = props.match.params.id;
    // if this is the first time the user visits this adventure, we diplay the start screen
    if(BuddyLogic.getIsFirstVisit(id)) {
      setModal.current(<Start />);
    }

    // payment info in the url (?payment=xxx) - display a modal window informing user about outcome
    const queryValues = QueryString.parse(props.location.search);
    const paymentResult = queryValues["payment"];
    if(paymentResult) {
      if(["success", "cancel", "failed"].includes(paymentResult)) {
        setModal.current(<PaymentResult result={paymentResult} />, {hideClose:true});
      }
    }

    // load the adventure
    loadAdventure.current(id);
  }, [props.match.params.id])

  useEffect(() => {
    // specific view? if so, we want to display the specific view (for testing purposes during development)
    // e.g. http://localhost:3004/5e72155b35314600160bdaa1/finalize
    const testingEnviroment = ["development", "staging"].includes(Config.environment);
    const mode = props.match.params.mode;
    if(testingEnviroment && mode && App.isLoaded ) {
      setMode.current(mode);
    }
    // get id
  }, [App.isLoaded, props.match.params.mode])

  // render
  let content
  switch(App.mode) {
    case "ready":
      content = <Catalogue />
      break;
    case "error":
      content = <Error />
      break;
    case "start":
      content = <Start />
      break;
    case "finalize":
      content = <Finalize />
      break;
    case "done":
      content = <Done />
      break;
    case "addfunds":
      content = <AddFunds />
      break;
    case "loading":
    default:
      content = <Loading />
      break;
  }
  return (
    <ScrollProvider>
      <div id="v-main">
        <div id="v-main-inner">
          {content}
        </div>
      </div>
      <Modal />
    </ScrollProvider>
  )
}

function Modal() {
  // context
  const App = useContext(AppContext);

  // state 
  const [hasContent, setHasContent] = useState(false);

  // modal component changed
  useEffect(() => {
    setHasContent(!_.isNil(App.modal));
  }, [App.modal])
  
  // close modal
  const close = () => {
    App.setModal(null);
    App.setActivityToCustomize(null); // TODO hmmm ... why is that sitting in here?
  }

  // render
  if(!hasContent) {
    return null
  }
  const closer = _.get(App.modalOptions, "hideClose") === true ? null : <Circle className="close" onClick={close}>&times;</Circle>
  return (
    <div id="v-main-modal">
      <div id="v-main-modal-inner">
        {App.modal}
        {closer}
      </div>
    </div>
  )
}

export default Main;