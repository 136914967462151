import React, {useState, useContext, useEffect, useRef} from "react";
import {AppContext} from "../../context/App.Context";
import {ScrollContext} from "../../context/Scroll.Context";
import {FaUser, FaUserFriends, FaCoins, FaMap, FaComment} from "react-icons/fa";
import {Circle, Logo} from "../controls";
import {Start} from "../views/index"

import "./AdventureInfo.scss";
/**
 * displays adventure information
 */
function Adventure() {
  // get context
  const App = useContext(AppContext);
  const Scroll = useContext(ScrollContext);

  // state
  const [showMin, setShowMin] = useState(false); // min, max

  // refs
  const refBottom = useRef()

  // scroll changed?
  useEffect(() => {
    const position = refBottom.current.getBoundingClientRect();
    if(!showMin) {
      if(position.top < 0) {
        setShowMin(true);
      }
    }
    else {
      if(position.top > 0) {
        setShowMin(false);
      }
    }
  }, [Scroll.timestamp, setShowMin, showMin])

  // render
  return <>
    <div id="v-adventure-info">
      <div className="max">
        <Logo size="small" color="black" />
        <div className="info">
          <table>
            <tbody>
              <tr>
                <th colSpan="2">Überraschung für {App.adventure.adventurerName}</th>
              </tr>
              <tr>
                <td>{App.adventure.participantCount === 1 ? <FaUser /> : <FaUserFriends />}</td>
                <td>{App.adventure.participantCount} Teilnehmer</td>
              </tr>
              <tr>
                <td><FaMap /></td>
                <td>{App.adventure.regionTitle}</td>
              </tr>
              <tr>
                <td><FaCoins /></td>
                <td><Budget adventure={App.adventure} /></td>
              </tr>
              <tr>
                <td><FaComment /> </td>
                <td>{(App.adventure.buddySelectionComment || "").split('\n').map((line, index) => <p key={index}>{line}</p>)}</td>
              </tr>
            </tbody>
          </table>
          <div ref={refBottom} />
        </div>
        <div className="help">
          <Circle onClick={() => App.setModal(<Start />)}>?</Circle>
        </div>
      </div>

      <div className={`min ${showMin ? "" : "hidden"}`}>
        <div className="min-inner">
          <Logo size="small" color="black" />
          <div className="info">
            <div className="heading">
              <div className="name">Überraschung für {App.adventure.adventurerName}</div>
            </div>
            <div className="details">
              <div>{App.adventure.participantCount === 1 ? <FaUser /> : <FaUserFriends />} {App.adventure.participantCount}</div>
              <div><FaCoins /> {App.adventure.value.toFixed(2)}</div>
              <div><FaMap /> {App.adventure.regionTitle}</div>
            </div>
          </div>
          <div className="help">
            <Circle onClick={() => App.setModal(<Start />)}>?</Circle>
          </div>
        </div>
      </div>
    
      
    </div>
  </>
}

function Budget({adventure}) {
  
  if(adventure.upsells && adventure.upsells.length > 0) {
    const parts = adventure.upsells.map(v => v.toFixed(2));
    parts.unshift(adventure.valueWithoutUpsells);
    return `CHF ${adventure.value.toFixed(2)} (${parts.join(' + ')}) Budget`;
  }
  else {
    return `CHF ${adventure.value.toFixed(2)} Budget`;
  }
}

export default Adventure