import _ from "lodash";
import React, {useState, useEffect, useContext, useRef} from "react";
import {AppContext} from "../../context/App.Context";
import {Activity, Select} from "../controls";
import {AdventureInfo, Cart} from "../subviews";
import {Customize} from "../views";

import "./Catalogue.scss";

function Catalogue() {
  // context
  const App = useContext(AppContext);

  // refs
  const refCartFoil = useRef(); // div at the very bottom, we will change its size to reflect the size of the cart

  // state
  const [activities, setActivities] = useState([]);
  const [totalActivitiesCout, setTotalActivitiesCount] = useState(0);
  const [sort, setSort] = useState("salesrank_asc");
  const [tags, setTags] = useState({gender:[], category:[]});

  // mount
  useEffect(() => {
    setTotalActivitiesCount(App.catalogue.activities.filter(a => !a.isSubactivity).length);
  }, [App.catalogue.activities])

  // fires when selected activity changes
  useEffect(() => {
    const disableBodyScrolling = (disabled) => {
      // TODO For mobile devices, you'll need to handle the touchmove event:
      // $('body').bind('touchmove', function(e){e.preventDefault()})
      // And unbind to re-enable scrolling. Tested in iOS6 and Android 2.3.3
      // $('body').unbind('touchmove')
      // see: https://stackoverflow.com/questions/4770025/how-to-disable-scrolling-temporarily
      if(disabled) {
        document.body.style.overflow = "hidden";
      }
      else {
        document.body.style.overflow = "auto";
      }
    }
    disableBodyScrolling(App.activityToCustomize !== null);

    // modal
    if(App.activityToCustomize && _.isNil(App.modal)) {
      const modal = (
        <Customize 
          activity={App.activityToCustomize} 
          onClose={() => {
            App.setActivityToCustomize(null);
            App.setModal(null);
          }} 
        />
      );
      App.setModal(modal);
    }
  }, [App, App.activityToCustomize]);

  // when filtering / sorting
  useEffect(() => {
    const filtered = App.catalogue.activities
      .filter(a => !a.isSubactivity)
      .filter(a => tags.gender.length === 0 ? true : _.intersection(tags.gender, a.tags).length > 0)
      .filter(a => tags.category.length === 0 ? true : _.intersection(tags.category, a.tags).length > 0)
    let sortedAndFiltered;
    switch(sort) {
      case "price_asc":
        sortedAndFiltered = filtered.sort((a, b) => a.regionVariant.priceConfiguration.price > b.regionVariant.priceConfiguration.price ? 1 : -1);
        break;
      case "price_desc":
        sortedAndFiltered = filtered.sort((a, b) =>  a.regionVariant.priceConfiguration.price > b.regionVariant.priceConfiguration.price ? -1 : 1);
        break;
      case "salesrank_asc":
        sortedAndFiltered = filtered.sort((a, b) => {
          let sort = a.salesRank - b.salesRank;
          if(a.isFoodUpsell || b.isFoodUpsell) {
            // we don't want food upsells to be top-ranked
            if(a.isFoodUpsell && b.isFoodUpsell) {
              return sort
            }
            else {
              return a.isFresh ? 1 : -1
            }
          }
          else {
            return sort
          }
        });
        break;
      case "name_asc":
      default:
        sortedAndFiltered = filtered.sort((a, b) => a.title > b.title ? 1 : -1);
    }
    setActivities(sortedAndFiltered);
  }, [sort, tags, App.catalogue])

  // when height of cart changes
  useEffect(() => {
    if(refCartFoil.current) {
      refCartFoil.current.style.height = `${App.cartHeight}px`;
    }
  }, [App.cartHeight])

  // gather items to render
  const items = activities.map(a => <Activity key={a._id} activity={a} onSelect={(activity) => App.setActivityToCustomize(activity)} />)

  // render
  return (
    <>
    <AdventureInfo />
    <div id="v-catalogue">
      <h1>Stell aus diesen Aktivitäten ein Erlebnis zusammen</h1>
      <div className="filter">
        <Sort onChange={v => setSort(v)} />
        <Tags tags={tags} onChange={t => setTags(t)} />
      </div>
      <div className="activities">{items}</div>
      <div className="count">{items.length} von {totalActivitiesCout} Aktivitäten</div>
      <div className="cart-foil" ref={refCartFoil}></div>
    </div>
    <Cart />
    </>
  );
}

/**
 * 
 * @param {*} param0 
 */
function Sort({onChange}) {
  // state
  const [sort, setSort] = useState("salesrank_asc");
  const [sortOptions] = useState([
    {value:"salesrank_asc", label:"Beliebtheit"},
    {value:"price_asc", label:"Preis aufsteigend"},
    {value:"price_desc", label:"Preis absteigend"},
    {value:"name_asc", label:"Name"}
  ])

  const changeSort = (v) => {
    setSort(v);
    onChange(v);
  }

  // render
  return (
    <div className="sort">
      <div className="heading">sortieren nach</div>
      <Select value={sort} options={sortOptions} onChange={changeSort} />
    </div>
  )
}

/**
 * 
 * @param {*} param0 
 */
function Tags({onChange, tags}) {
  const toggle = ({tag, group}) => {
    const updated = {gender:tags.gender, category:tags.category}; // use _.cloneDeep to make this more versatile if we have more than these two groups
    updated[group] = tags[group].filter(t => t !== tag);
    if(updated[group].length === tags[group].length) {
      updated[group].push(tag);
    }
    onChange(updated);
  }
  return (
    <div className="tags">
      <div className="heading">filtern nach</div>
      <div className="tags-inner">
        <Tag group="gender" tag="Frauen" activeTags={tags} onToggle={toggle} />
        <Tag group="gender" tag="Männer" activeTags={tags} onToggle={toggle} />
        <Tag group="gender" tag="Paare" activeTags={tags} onToggle={toggle} />
        <span>|</span>
        <Tag group="category" tag="Kulinarisches" label="Kulinark" activeTags={tags} onToggle={toggle} />
        <Tag group="category" tag="Fun" activeTags={tags} onToggle={toggle} />
        <Tag group="category" tag="Wellness" activeTags={tags} onToggle={toggle} />
      </div>
    </div>
  )
}

function Tag({tag, label, group, onToggle, activeTags}) {
  const active = activeTags[group].includes(tag);
  return (
    <span onClick={() => onToggle({tag, group})} className={`tag${active ? ' active':'' }`}>
      <span className="tag-inner">{label || tag}</span>
    </span>
  );
}

export default Catalogue