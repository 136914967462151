import _ from "lodash";
import React, {useContext, useState} from "react";
import {AppContext} from "../../context/App.Context";
import {Button, Logo} from "../controls";
import {MdMailOutline, MdPhoneIphone} from "react-icons/md";
import Images from "../images";
import "./Error.scss";

export default function Error() {
  // get the context
  const App = useContext(AppContext);

  // state
  const [showDetails, setShowDetails] = useState(false);
  
  // show / hide details
  let detailsToggle = null;
  let details = null;
  if(showDetails) {
    details = (
      <div className="details">
        <div className="details-code">code: {_.get(App, "error.code") || "UNKNOWN_ERROR"}</div>
        <div className="details-message">message: {_.get(App, "error.message")}</div>
      </div>
    )
  }
  else {
    detailsToggle = <div className="details-toggle clickable" onClick={() => setShowDetails(true)}>Details anzeigen</div>
  }

  // render
  return (
    <div id="v-error">
      <div id="v-error-inner">
        <div className="heading">
          <Logo size="medium" color="black" />
        </div>
        <div className="sad">
          <img src={Images.icons.black.sad} alt="sad face" />
        </div>
        {getText(_.get(App, "error.code"))}
        {detailsToggle}
        {details}
        <div className="contact">
          <p>
            Du darfst gerne mit uns Kontakt aufnehmen: 
          </p>
          <ul>
            <li>
              <MdMailOutline /> <a href="mailto:hello@appentura.ch">hello@appentura.ch</a>
            </li>
            <li>
              <MdPhoneIphone /> <a href="tel:+41315112132">+41 31 511 21 32</a>
            </li>
          </ul>
        </div>
        <div className="console">
          <Button onClick={() => window.location.reload()} >noch mal versuchen</Button>
        </div>
      </div>
    </div>
  )
}

function getText(code) {
  code = code || "UNKNOWN_ERROR";

  const UNKNOWN_ERROR = <>
    <h1>Unbekannter Fehler</h1>
    <p>Hoppla, da ist wohl etwas schief gelaufen.</p>
  </>

  const WRONG_STATUS = <>
    <h1>Falscher Status</h1>
    <p>Diese Überraschung ist im falschen Status und kann darum nicht bearbeitet werden.</p>
  </>

  const SURPRISE_LOAD = <>
    <h1>Fehler beim Laden der Überraschung</h1>
    <p>Die Überraschung konnte leider nicht geladen werden.</p>
  </>

  const NOT_FOUND = <>
    <h1>Diese Überraschung existiert nicht</h1>
    <p>Leider konnte die angefrage Überraschung nicht gefunden werden.</p>
  </>

  const CATALOGUE_LOAD = <>
    <h1>Fehler beim Laden des Katalogs</h1>
    <p>Der Katalog der verfügbaren Aktivitäten konnte leider nicht geladen werden.</p>
  </>

  const BUDGET_EXCEEDED = <>
    <h1>Budget überschritten</h1>
    <p>Das Budget für diese Überraschung wurde leider überschritten.</p>
  </>

  const SURPRISE_SAVE = <>
    <h1>Überraschung konnte nicht gespeichert werden</h1>
    <p>Leider konnte die Überraschung nicht gespeichert werden.</p>
  </>

  const texts = {
    UNKNOWN_ERROR, WRONG_STATUS, SURPRISE_LOAD, NOT_FOUND, CATALOGUE_LOAD, BUDGET_EXCEEDED, SURPRISE_SAVE
  }
  

  console.log(texts[code])
  return texts[code] || texts[UNKNOWN_ERROR];
}