import React, {useState, useEffect} from 'react'

import "./AsyncImage.scss"

function AsyncImage({src, alt, className}) {
  const [loaded, setLoaded] = useState(false)

  // mount
  useEffect(() => {
    const imageElement = new Image();
    imageElement.onload = onLoad;
    imageElement.onerror = onError;
    imageElement.src = src;
  }, [src])

  const onLoad = () => {
    setLoaded(true)
  }
  const onError = () => {
    // TODO
  }

  // render
  let img = null
  if(loaded) {
    img = <img src={src} alt={alt} />
  }
  else {
    img = "loading"
  }
  return (
    <div className={`c-asyncimage ${className || ''} ${!loaded ? 'loading' : 'loaded'}`}>
      {img}
    </div>
  )
}

export default AsyncImage
