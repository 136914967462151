import React from 'react';

import './Loader.scss';

export default function Loader({text}) {
  const divText = (text || "").trim().length > 0 ? <div className="c-loader-text">{text}</div> : null
  return <div className="c-loader">
    {divText}
    <div className="c-loader-line" />
  </div>
}