const config = {
  development: {
    
  },
  staging: {
    
  },
  production: {
    
  }
};

export default config