import React, {useState, useEffect} from "react";

import "./Select.scss";

export default function Select({options, onChange, value}) {
  // state
  const [selectedValue, setSelectedValue] = useState(value);

  // mount
  useEffect(() => {
    setSelectedValue(value);
  }, [value])
  
  // mount
  useEffect(() => {
  }, []);

  // change
  const change = (e) => {
    setSelectedValue(e.target.value);
    if(onChange) {
      onChange(e.target.value);
    }
  }

  // render
  return (
    <div className="c-select">
      <select value={selectedValue} onChange={change}>
        {options.map((option, index) => <option key={option.value} value={option.value}>{option.label}</option>)}
      </select>
    </div>
  );
}
