import _ from "lodash";
import React, {useState, useEffect} from "react";
import BuddyLogic from "./Buddy.Logic";
import CartLogic from "./Cart.Logic";
import AppLogic from "./App.Logic";

const AppContext: Object = React.createContext();

function AppProvider(props) {
  // state
  const [mode, setMode] = useState("loading") // loading, start, ready, error, finalize, addfunds, done
  const [catalogue, setCatalogue] = useState(null); // {activities, charities}
  const [activityToCustomize, setActivityToCustomize] = useState(null);
  const [adventure, setAdventure] = useState(null);
  const [error, setError] = useState(null);
  const [cart, setCart] = useState({items:[], total:0});
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [modal, setModal] = useState(null);
  const [modalOptions, setModalOptions] = useState({hideClose:false});
  const [cartHeight, setCartHeight] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  // updates the cart in the state and stores it in localStorage
  const updateCart = (items) => {
    const total = CartLogic.getTotal(items);
    setCart({total, items});
    CartLogic.store(adventure, {total, items});
  }
  
  // mount
  useEffect(() => {}, [])
  
  // provider value
  const value = {
    // props
    mode,
    catalogue, // {activities, charities}
    activityToCustomize,
    adventure,
    error,
    cart,
    selectedCharity,
    modal,
    modalOptions,
    cartHeight,
    isLoaded,

    // loads the adventure
    loadAdventure: async(id, nextMode) => {
      const result = await BuddyLogic.loadAdventureAndCatalogue(id);
      if(result.success) {
        setAdventure(result.adventure);
        setCatalogue(result.catalogue);
        const cartFromStorage = CartLogic.restore(result.adventure);
        if(cartFromStorage) {
          setCart(cartFromStorage);
          // setActivityToCustomize(result.catalogue.activities[0]); // TODO just for testing
        }
        // change app mode if necessary
        nextMode = _.isNil(nextMode) ? "ready" : nextMode;
        if(mode !== nextMode) {
          setMode(nextMode);
        }
        setIsLoaded(true);
      }
      else {
        setModal(null);
        setError(result.error);
        setMode("error");
      }
    },
    // submits adventure
    submitAdventure: async() => {
      const result = await AppLogic.submitAdventure(adventure.id, selectedCharity, cart.items);
      return result;
    },
    // switches mode 
    setMode: (nextMode) => { 
      setMode(nextMode); 
    },
    // error
    setError: (error) => { 
      setModal(null);
      setError(error); 
    },
    // set which activity to customize
    setActivityToCustomize: (activity) => { 
      setActivityToCustomize(activity);
    },
    // add activity
    addToCart: (activity) => {
      const items = cart.items.filter(a => a._id !== activity._id).map(a => a);
      items.push(activity);
      updateCart(items);
    },
    removeFromCart: (activity) => {
      activity.__selectedOptions = [];
      activity.__selectedSubactivities = [];
      const items = cart.items.filter(a => a._id !== activity._id).map(a => a);
      updateCart(items);
    },
    isInCart: (activity) => {
      return !_.isNil(cart.items.find(a => a._id === activity._id));
    },
    selectCharity: (charity) => {
      setSelectedCharity(charity);
    },
    subscribeToNewsletter: async(email) => {
      const result = await AppLogic.subscribeToNewsletter(email);
      return result;
    },
    setModal: (component, options) => {
      setModal(component);
      options ? setModalOptions(options) : setModalOptions({hideClose:false});
    },
    setCartHeight: (h) => {
      if(h !== cartHeight) {
        setCartHeight(h);
      }
    }
  }

  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )
}

export {AppContext, AppProvider}