import AppLogic from "./App.Logic";
import Catalogue from "./Catalogue.Logic.js";

async function loadAdventureAndCatalogue(adventureId) {
  const result = {success:true, adventure:{id:""}, catalogue:null, error:{title:"", message:""}};
  
  // load adventure
  const resultAdventure = await AppLogic.loadAdventure(adventureId);
  if(resultAdventure.success) {
    result.adventure = resultAdventure.adventure;
    // load catalogue
    const resultCatalogue = await Catalogue.load(result.adventure);
    if(resultCatalogue.success) {
      result.catalogue = resultCatalogue;
    }
    else {
      result.success = false;
      result.error = resultCatalogue.error;
    }
  }
  else {
    result.success = false;
    result.error = resultAdventure.error;
  }
  
  // done
  return result;
}

function getIsFirstVisit(adventureId) {
  const value = localStorage.getItem(`firstVisit_${adventureId}`);
  if(value) {
    return JSON.parse(value);
  }
  else {
    return true;
  }
}
function setIsFirstVisit(adventureId, yesno) {
  localStorage.setItem(`firstVisit_${adventureId}`, JSON.stringify(yesno));
}

export default {
  loadAdventureAndCatalogue,
  getIsFirstVisit,
  setIsFirstVisit
}