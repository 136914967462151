import _ from "lodash";
import React, {useContext} from "react";
import {AppContext} from "../../context/App.Context";
import CartLogic from "../../context/Cart.Logic";
import {Button} from "../controls";
import {AdventureInfo, Footer} from "../subviews";


import "./Finalize.scss";

function Finalize() {
  // render
  return <>
    <AdventureInfo />
    <div id="v-finalize">
      <div id="v-finalize-inner">
      <h1>Abschliessen</h1>
        <Items />
        <Charities />
        <Submit />
      </div>
      <Footer />
    </div>
  </>
}

function Items() {
  // context
  const App = useContext(AppContext);
  
  // render
  const items = App.cart.items.map(activity => {
    const pricing = CartLogic.getActivityPricing(activity);
    return (
      <tr key={activity._id}>
        <td>
          <div className="image">
            <img src={activity.defaultImage.url} alt={activity.title} />
          </div>
        </td>
        <td>
          {activity.title}
        </td>
        <td className="right">
          {Number(pricing.total).toFixed(2)}
        </td>
      </tr>
    )
  })

  return (
    <div id="v-finalize-items">
      <h2>Zusammenfassung</h2>
      <table><tbody>
        {items}
        <tr className="total">
          <td></td>
          <td>Total</td>
          <td className="right">{Number(App.cart.total).toFixed(2)}</td>
        </tr>
      </tbody></table>
      <div className="console">
        <Button onClick={() => App.setMode("ready")}>bearbeiten</Button>
      </div>
    </div>
  )
}

function Charities() {
  // get the context
  const App = useContext(AppContext);

  // calculate remainder, if === 0 we don't need to select a charity
  const remainder = App.adventure.value - App.cart.total;
  if(remainder === 0) {
    return null
  }
  // render
  const items = App.catalogue.charities.map(charity => {
    const selected = charity._id === (App.selectedCharity ? App.selectedCharity._id : "");
    return (
      <div className={`c-charity ${selected ? "selected" : ""}`} key={charity._id} onClick={() => App.selectCharity(charity)} >
        <div className="c-charity-inner">
          <div className="logo"><img src={charity.imageUrl} alt={charity.name.de} /></div>
          <h3>{charity.name.de}</h3>
          <div className="description">{charity.description.de}</div>
        </div>
      </div>
    )
  })
  return (
    <div id="v-finalize-charities">
      <h2>Wähle einen wohltätige Organisation</h2>
      <div className="explanation">
      <p>Du hast das Budget nicht vollständig ausgeschöpft.</p>
      <p>Wir werden den Restbetrag von CHF {Number(remainder).toFixed(2)} einer wohltätigen Organisation zukommen lassen.</p>
      <p>Wähle bitte eine Organisation.</p>
      </div>
      <div className="charities">
        {items}
      </div>
    </div>
  )
}

function Submit() {
  // get the context
  const App = useContext(AppContext);

  // TODO app needs to look busy when adventure is submitted
  const submit = async() => {
    const result = await App.submitAdventure();
    if(result.success) {
      App.setMode("done");
    }
    else {
      App.setError(result.error);
      App.setMode("error");
    }
  }

  // calculate the remainder
  const remainder = App.adventure.value - App.cart.total;

  return (
    <div id="v-finalize-submit">
      <h2>Abschliessen</h2>
      <div className="explanation">
        <p>Sobald du dieses Formular abgeschickt hast, werden wir {App.adventure.adventurerName} informieren. Als nächstes wird {App.adventure.adventurerName} ein Datum für die Überraschung wählen, und wir kümmern uns dann um die komplette Organisation und einen reibungslosen Ablauf.</p> 
        <p>Danke für deine Zusammenarbeit - {App.adventure.adventurerName} wird sich bestimmt freuen.</p>
      </div>
      <div className="console">
        <Button onClick={() => App.setMode("ready")}>zurück</Button>
        <Button disabled={_.isNil(App.selectedCharity) && remainder !== 0} onClick={submit}>abschicken</Button>
      </div>
    </div>
  )
}

export default Finalize